const videoCollection = [
  {
    thumb: 'share-with-caregivers-thumbnail.png',
    copy: `Watch to better understand how CPP affects your child and about the treatment option, <span class='block_nowrap'>SUPPRELIN<sup>&reg;</sup> LA</span>`,
    wistiaID: '8f2xlrptxn',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/8f2xlrptxn?videoFoam=true&doNotTrack=true',
    alt: 'How CPP affects your child and about the treatment option, SUPPRELIN® LA Video',
  },
  {
    thumb: '5goodreasons_video_thumbnail.png',
    copy: `5 important considerations for your child with CPP`,
    wistiaID: '6w7292pjs8',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/6w7292pjs8?videoFoam=true&doNotTrack=true',
    alt: '5 Important Considerations Video',
  },
  {
    thumb: 'davidlanning_video_thumbnail.png',
    copy: `Watch an experienced doctor discuss how he uses <span class='block_nowrap'>SUPPRELIN<sup>&reg;</sup> LA</span>`,
    wistiaID: 'eawbrakdin',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/eawbrakdin?videoFoam=true&doNotTrack=true',
    alt: 'Experienced Doctor Video',
  },
  {
    thumb: 'nurse_video_thumbnail.png',
    copy: `Hear from a mother – and nurse – whose child has been treated with <span class='block_nowrap'>SUPPRELIN<sup>&reg;</sup> LA</span>`,
    wistiaID: 'rucsd8tdxv',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/rucsd8tdxv?videoFoam=true&doNotTrack=true',
    alt: 'Mother Nurse Video',
  },
  {
    thumb: 'lizzy_and_geraldine_thumbnail.png',
    copy: `Watch Lizzy and Geraldine share their CPP journey`,
    wistiaID: '7e26cjcoa9',
    iframeUrl:
      '//fast.wistia.net/embed/iframe/7e26cjcoa9?videoFoam=true&doNotTrack=true',
    alt: 'Lizzy and Geraldine Video',
  },
]

export default videoCollection
